import { Injectable, inject } from '@angular/core';
import { LanguageCode, LanguageCodes, shortCodeLanguageToLanguageCodeMap } from '@app-shared/enums/language-codes.enum';
import { createStore, emitOnce, select, setProp, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { TranslocoService } from '@ngneat/transloco';
import { shareReplay } from 'rxjs';

interface SettingsProps {
  theme: string | null;
  language: LanguageCodes | null;
  isGearSubdomain: boolean;
}

const initialProps: SettingsProps = {
  theme: null,
  language: null,
  isGearSubdomain: false,
};

const STORE_NAME = 'manpower-settings';

@Injectable({ providedIn: 'root' })
export class SettingsRepository {
  private readonly transloco = inject(TranslocoService);

  private readonly store;

  readonly theme$;
  readonly language$;
  readonly isGearSubdomain$;

  constructor() {
    this.store = createStore({ name: STORE_NAME }, withProps<SettingsProps>(initialProps));

    persistState(this.store, {
      key: STORE_NAME,
      storage: localStorageStrategy,
    });

    this.theme$ = this.store.pipe(
      select(state => state.theme),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.language$ = this.store.pipe(
      select(state => state.language),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.isGearSubdomain$ = this.store.pipe(
      select(state => state.isGearSubdomain),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  getStoreProp<K extends keyof SettingsProps>(key: K): SettingsProps[K] {
    return this.store.getValue()[key];
  }

  setStoreProp<K extends keyof SettingsProps, V extends SettingsProps[K]>(key: K, value: V): void {
    this.store.update(setProp(key, value));
  }

  /**
   * Function that sets the selected app language in store.
   *
   * Transloco service is also used to update active transloco language.
   *
   * @param selectedLang
   */
  setLanguage(selectedLang: SettingsProps['language']): void {
    emitOnce(() => {
      this.transloco.setActiveLang(selectedLang || LanguageCode.ENGLISH_USA);
      this.store.update(setProp('language', selectedLang));
    });
  }

  /**
   * When receiving lang in a string format use this function to set
   * the language from one of our available languages.
   *
   * @param lang
   */
  setLanguageFromString(lang: string): void {
    const availableLangs = Object.values(LanguageCode);
    const foundLanguage = availableLangs.find(l => l === lang);

    if (foundLanguage) {
      this.setLanguage(foundLanguage);
    }
    // if language not found, see if it is in short code
    else {
      this.setLanguage(shortCodeLanguageToLanguageCodeMap.get(lang));
    }
  }
}
